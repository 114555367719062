<template>
  <v-sheet
    rounded="4"
    class="p-8 bg-pastel-lime text-type-secondary"
  >
    <v-row
      no-gutters
      class="gap-x-8 fill-height"
    >
      <v-col
        v-if="mdAndUp"
        cols="auto"
      >
        <v-img
          :src="reviewOshaan"
          :draggable="false"
          alt="Oshaan"
          rounded="2"
          color="#8EE9D8"
          cover
          v-bind="imgProps"
        >
          <template #sources>
            <source :srcset="reviewOshaanWebp">
          </template>
        </v-img>
      </v-col>
      <v-col class="flex flex-col">
        <u-text class="text-body-lead font-bold italic grow">
          "I was pleasantly surprised at the quick turnaround time and the highly professional (and affordable) partner they connected me with."
        </u-text>
        <u-text
          class="text-body-lg max-sm:mt-4"
          text="Oshaan (via Trustpilot)"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script setup lang="ts">
import reviewOshaan from '~/assets/images/home/review-oshaan.png'
import reviewOshaanWebp from '~/assets/images/home/review-oshaan.webp'

const { xxl, lgAndDown, mdAndUp } = useDisplay()
const imgProps = computed(() => {
  if (lgAndDown.value) {
    return {
      minWidth: 272,
      height: '100%',
    }
  }

  if (xxl.value) {
    return {
      width: 272,
      aspectRatio: 272 / 413,
    }
  }

  return {
    minWidth: 120,
    height: '100%',
    aspectRatio: 120 / 232,
  }
})
</script>
