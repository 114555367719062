<template>
  <v-img
    :src="reviewBarbara"
    :draggable="false"
    alt="Barbara"
    rounded="4"
    class="bg-black-8"
    cover
    v-bind="props"
  >
    <template #sources>
      <source :srcset="reviewBarbaraWebp">
    </template>
  </v-img>
</template>

<script setup lang="ts">
import reviewBarbara from '~/assets/images/home/review-barbara.png'
import reviewBarbaraWebp from '~/assets/images/home/review-barbara.webp'

const { lgAndDown } = useDisplay()

const props = computed(() => {
  return lgAndDown.value
    ? {
        aspectRatio: 1,
        position: 'top',
      }
    : {
        minHeight: 568,
      }
})
</script>
