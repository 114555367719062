<template>
  <v-sheet
    v-editable="story.content"
    width="100%"
  >
    <delay-hydration>
      <hero-section :blok="story.content" />

      <v-sheet
        v-if="lgAndUp"
        color="surface-bg-secondary"
      >
        <v-container>
          <partners-section />
        </v-container>
      </v-sheet>
    </delay-hydration>

    <lazy-wrapper-section class="bg-gradient-to-r from-[#016469] from-0% via-primary-teal via-50% to-[#02666B] to-100%">
      <v-container>
        <u-text
          class="text-heading-h2 mb-2 text-center"
          color="white"
        >
          Why Unbiased?
        </u-text>
        <u-text
          class="text-body-lead text-center mb-8 lg:mb-14 xl:mb-16"
          color="white"
        >
          We’ve helped more than 10 million people to find tailored financial advice
        </u-text>

        <v-row class="max-lg:gap-y-6">
          <template
            v-for="(icon, index) in icons"
            :key="index"
          >
            <v-col
              cols="24"
              lg
              class="lg:flex sm:gap-x-4 sm:text-center lg:text-left"
            >
              <u-icon
                :icon="icon.icon"
                :color="icon.color"
                :size="xs ? 'default' : 'large'"
              />
              <div class="max-lg:mt-4">
                <u-text
                  class="text-heading-h3 mb-2"
                  color="white"
                  :text="icon.title"
                />
                <u-text
                  class="text-body-lg"
                  color="white"
                >
                  <span v-html="icon.content" />
                </u-text>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </lazy-wrapper-section>

    <lazy-wrapper-section>
      <v-container>
        <v-sheet
          class="rounded-4 overflow-hidden"
          color="surface-bg-secondary"
        >
          <v-row no-gutters>
            <v-col
              cols="24"
              lg="12"
            >
              <v-img
                :height="xs ? 364 : '100%'"
                :src="howItWorks"
                cover
              />
            </v-col>
            <v-col
              cols="24"
              lg="12"
              class="p-6 sm:py-14 sm:px-8 lg:py-16 lg:px-10"
              align-self="center"
            >
              <u-text
                color="type-secondary"
                class="text-heading-h2 mb-12"
                text="How it works"
              />

              <template
                v-for="(b, index) in howItWorksBlock"
                :key="index"
              >
                <v-row
                  no-gutters
                  :class="{ 'mb-8': howItWorksBlock.length > index + 1 }"
                >
                  <v-col
                    cols="24"
                    sm="auto"
                  >
                    <v-avatar
                      :text="String(index + 1)"
                      :size="mdAndDown ? xs ? 42 : 56 : 72"
                      color="type-primary"
                      class="text-heading-h3 max-sm:mb-4 sm:me-6"
                    />
                  </v-col>
                  <v-col
                    cols="24"
                    sm
                  >
                    <u-text
                      color="type-primary"
                      class="text-body-lead font-bold mb-2"
                      :text="b.title"
                    />
                    <u-text
                      color="type-secondary"
                      class="text-body-lg"
                      :text="b.content"
                    />
                  </v-col>
                </v-row>
              </template>

              <u-btn
                v-if="!xs"
                size="large"
                text="Match to an adviser"
                class="mt-12"
                :href="`${v2Url}/enquiry`"
              />
            </v-col>
          </v-row>
        </v-sheet>
      </v-container>
    </lazy-wrapper-section>

    <lazy-wrapper-section class="xl:pt-6">
      <v-container fluid>
        <u-text class="text-heading-h2 mb-10 text-center">
          Real connections with <br class="max-sm:hidden"> real results
        </u-text>

        <home-reviews />
      </v-container>
    </lazy-wrapper-section>

    <template
      v-for="(section, index) in story.content.sections"
      :key="index"
    >
      <lazy-wrapper-section
        v-if="isFaq(section) || section.links?.length"
        :class="{ 'bg-gradient-to-r from-[#016469] from-0% via-primary-teal via-50% to-[#02666B] to-100%': section.links?.length }"
      >
        <v-container>
          <v-row v-if="section.links?.length">
            <v-col
              cols="24"
              xxl="6"
            >
              <u-text
                class="text-heading-h2 mb-6 sm:mb-2 max-sm:text-center"
                color="white"
                :text="section.title"
              />
              <u-text
                v-if="section.subtitle"
                color="white"
                class="text-body-lead max-sm:text-center max-xxl:mb-2 max-lg:mb-4"
              >
                <u-content
                  :document="section.subtitle"
                  :resolvers="resolvers"
                />
              </u-text>
            </v-col>
            <v-col
              cols="24"
              xxl
            >
              <v-row>
                <v-col>
                  <v-row>
                    <template
                      v-for="(link, index) in links"
                      :key="index"
                    >
                      <v-col
                        cols="24"
                        md="12"
                        xl="8"
                      >
                        <v-sheet
                          class="px-6 pb-6 pt-16 rounded-4 text-center flex flex-col"
                          height="100%"
                          min-height="356"
                        >
                          <u-illustration
                            :name="link.icon.name"
                            :fill="link.icon.color"
                            max-height="92"
                            class="mx-auto mb-6"
                          />
                          <u-text
                            color="type-secondary"
                            class="text-body-lg mb-4 grow"
                            :text="link.title"
                          />
                          <u-btn
                            variant="secondary"
                            size="large"
                            v-bind="getLink(link.full_slug).props"
                            :text="link.name"
                            @click="trackEvent({ event: 'gaEvent', eventAction: 'click', eventCategory: 'homepage', eventLabel: `homepage_content_${slug(link.name, '_')}_block` })"
                          />
                        </v-sheet>
                      </v-col>
                    </template>
                    <v-col xl="8">
                      <v-sheet
                        class="px-6 pb-6 pt-16 rounded-4 text-center flex items-center"
                        height="100%"
                        min-height="356"
                      >
                        <div class="w-full">
                          <u-text
                            color="type-primary"
                            class="text-heading-h4 mb-4"
                            text="Match with an adviser for free"
                          />
                          <u-btn
                            size="large"
                            text="Get started"
                            :href="`${v2Url}/enquiry`"
                          />
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <template
            v-for="(content) in section.content"
            :key="content._uid"
          >
            <v-row
              v-if="isFaq(section)"
              no-gutters
              justify-sm="center"
            >
              <v-col
                cols="24"
                sm="20"
                md="18"
                lg="16"
              >
                <u-text
                  class="text-heading-h2 text-center mb-8"
                  :text="section.title"
                />

                <storyblok-component
                  :blok="content"
                  :gtm="{ event: 'gaEvent', category: 'homepage', action: 'click', label: 'homepage_faq' }"
                />
              </v-col>
            </v-row>
          </template>
        </v-container>
      </lazy-wrapper-section>

      <lazy-wrapper-section
        v-if="section.proTheme"
        color="surface-elevated-6"
        theme="pro"
      >
        <v-container class="text-center">
          <div class="flex justify-center mb-6 sm:mb-8">
            <v-avatar
              class="-me-2"
              :size="xs ? 44 : 74"
              border="2 white"
              color="#6B2C6A"
              style="--v-border-opacity: 1"
            >
              <v-img :src="proMen" />
            </v-avatar>
            <v-avatar
              class="-ms-2"
              :size="xs ? 44 : 74"
              border="2 white"
              color="#6B2C6A"
              style="--v-border-opacity: 1"
            >
              <v-img :src="proWomen" />
            </v-avatar>
          </div>
          <u-logo
            pro
            class="mx-auto mb-6 sm:mb-16"
            :width="xs ? 168 : 284"
          />
          <u-text
            color="type-primary"
            class="text-heading-h2 mb-6 sm:mb-4"
            :text="section.title"
          />
          <u-text
            color="type-primary"
            class="text-body-lead"
          >
            <u-content
              :document="section.subtitle"
              :resolvers="resolvers"
            />
          </u-text>
          <div
            v-if="section.cta && section.cta.length"
            class="text-center mt-6 sm:mt-8"
          >
            <storyblok-component
              v-for="(cta, ctaIndex) in section.cta"
              v-bind="getLink(cta.url).props"
              :key="ctaIndex"
              :blok="cta"
              :text="cta.text"
              :variant="cta.variant"
              :size="cta.size"
              @click="trackEvent({ event: 'gaEvent', eventAction: 'click', eventCategory: 'homepage', eventLabel: cta.url.label })"
            />
          </div>
        </v-container>
      </lazy-wrapper-section>
    </template>
  </v-sheet>
</template>

<script setup lang="ts">
import slug from 'slug'
import { BlockTypes } from '@storyblok/richtext'
import proMen from '~/assets/images/home/pro-men.png'
import proWomen from '~/assets/images/home/pro-women.png'
import { UBtn } from '#components'
import { paragraph } from '#storyblok/utils/resolvers/block-types'
import howItWorks from '~/assets/images/home/how-it-works.jpg'

const { v2Url, cmsUrl, usCmsUrl } = useRuntimeConfig().public
const story = await usePage({
  bridgeOptions: {
    resolveRelations: ['homeSection.links'],
    resolveLinks: 'url',
  },
})
const { xs, mdAndDown, lgAndUp } = useDisplay()
const { getLink } = useLinkType()
const { trackEvent } = useGtm()

const icons = [
  {
    icon: 'thumbs-up-duotone',
    color: 'eucalyptus',
    title: 'Trusted',
    content: 'Unbiased works with 27,000 <br/> qualified financial professionals.',
  },
  {
    icon: 'lightning-duotone',
    color: 'peach',
    title: 'Fast',
    content: 'Get matched with an adviser and contacted within 24 hours.',
  },
  {
    icon: 'currency-gbp-duotone',
    color: 'mauve',
    title: 'Free',
    content: 'No charge - matching with an adviser <br/> is completely free.',
  },
]
const resolvers = {
  [BlockTypes.PARAGRAPH]: paragraph({
    class: false,
  }),
}
const defaultLinks = {
  'Pensions & Retirement': {
    icon: {
      name: 'coins',
    },
    title: 'Understand your pension options and live the retirement you’ve always wanted.',
  },
  'Mortgages & Property': {
    icon: {
      name: 'house',
    },
    title: 'Simplify everything from buying your first home to remortgaging or releasing equity.',
  },
  'Personal Finance': {
    icon: {
      name: 'couple',
      color: '#E4B2F6',
    },
    title: 'Grow your wealth, from small savings to smart lump-sum investments.',
  },
  'Insurance': {
    icon: {
      name: 'umbrella',
    },
    title: 'Navigate insurance with ease, from life cover to critical illness protection.',
  },
  'Tax & Business': {
    icon: {
      name: 'calculator',
    },
    title: 'Make tax simple – for both personal and business finances.',
  },
}
const howItWorksBlock = [
  {
    title: 'Answer a few questions',
    content: 'Share some details about your financial situation, it only takes a few minutes. Your information is confidential, encrypted, and stored securely by us.',
  },
  {
    title: 'Match with an adviser',
    content: 'Our advanced algorithm finds you the best adviser for your needs. All financial advisers and mortgage brokers listed with us are FCA-regulated and qualified.',
  },
  {
    title: 'Get your free first consultation',
    content: 'Your adviser will reach out to schedule your free initial consultation. You’ll discover your financial opportunities, with no obligation to continue.',
  },
]
const links = computed(() => {
  const _links = story.value.content.sections?.find(section => section.links?.length)?.links

  return _links?.map(link => ({
    ...defaultLinks[link.name as keyof typeof defaultLinks],
    ...link,
  }))
})

const isFaq = (section: Record<any, any>) => section.content?.some((content: Record<any, any>) => content.component === 'faq')

useHead({
  link: [
    { rel: 'alternate', hreflang: 'en-gb', href: cmsUrl },
    { rel: 'alternate', hreflang: 'en-us', href: usCmsUrl },
  ],
})

useSchemaOrg([
  defineOrganization({
    name: 'Unbiased',
    alternateName: 'Unbiased.co.uk',
    telephone: '+44-800-020-9430',
    logo: '~/assets/images/logo.svg',
    sameAs: [
      'https://www.linkedin.com/company/683058',
      'https://twitter.com/unbiaseduk',
      'https://plus.google.com/+Unbiased',
      'https://www.youtube.com/channel/UCziC5nQ17nPzQUtnaL9DODw',
      'https://www.facebook.com/unbiaseduk',
      'https://en.wikipedia.org/wiki/Unbiased.co.uk',
      'https://www.instagram.com/unbiaseduk/',
    ],
  }),
  defineAddress({
    streetAddress: '32-38 Saffron Hill',
    addressLocality: 'London',
    postalCode: 'EC1N 8FH',
    addressCountry: 'GB',
    alternateName: 'unbiased',
  }),
  defineAggregateRating({
    ratingValue: '4',
    bestRating: '5',
    ratingCount: '10681',
    worstRating: '1',
  }),
])
</script>
