<template>
  <v-sheet
    :min-height="xlAndUp ? 364 : 0"
    rounded="4"
    class="flex flex-col p-8 text-type-secondary"
    color="surface-elevated-2"
  >
    <u-text class="text-body-lead font-bold italic grow">
      "Unbiased was easy to use. I got a quick response to my query and the person recommended was exactly right for what I needed. I'd recommend this service to anyone."
    </u-text>
    <u-text
      class="text-body-lg max-sm:mt-4"
      text="Barbara (via Trustpilot)"
    />
  </v-sheet>
</template>

<script setup lang="ts">
const { xlAndUp } = useDisplay()
</script>
