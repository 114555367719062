<template>
  <template v-if="lgAndDown">
    <client-only>
      <flicking
        class="sm:-mx-8 -mx-4 px-4 sm:px-8 pb-10"
        :options="sliderOptions"
        :plugins="plugins"
      >
        <template v-if="mdAndUp">
          <div
            key="barbara"
            class="flex gap-x-6 me-6 w-full"
          >
            <barbara-review
              width="50%"
              max-height="364"
            />
            <barbara-review-img
              width="50%"
              max-height="364"
            />
          </div>
        </template>
        <barbara-review
          v-else
          key="barbara"
          class="me-6"
        />
        <oshaam-review
          key="oshaam"
          class="me-6"
        />
        <graham-review
          key="graham"
          class="me-6"
        />
        <template #viewport>
          <div class="flicking-pagination" />
        </template>
      </flicking>
    </client-only>
  </template>
  <v-row v-else>
    <v-col cols="24">
      <v-row align="end">
        <v-col
          xl="16"
          xxl="7"
        >
          <barbara-review />
        </v-col>
        <v-col
          xl="8"
          xxl="5"
        >
          <barbara-review-img />
        </v-col>
        <v-col
          v-if="xxl"
          xxl="12"
        >
          <oshaam-review />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      xl="8"
      xxl="12"
    >
      <graham-review />
    </v-col>
    <v-col
      xl="16"
      xxl="8"
      class="max-xxl:flex max-xxl:flex-col gap-y-6"
    >
      <oshaam-review
        v-if="xl"
        class="grow"
      />
      <v-sheet
        rounded="4"
        class="p-8"
        color="surface-bg-secondary"
      >
        <v-row no-gutters>
          <v-col class="flex flex-col">
            <u-text class="text-body-lead font-bold mb-4 grow">
              Years of providing financial confidence
            </u-text>
            <u-text
              class="font-galano xxl:text-right leading-none"
              text="14"
              font-size="112"
              color="type-primary"
            />
          </v-col>
          <v-divider
            opacity="1"
            class="max-xxl:mx-6 xxl:my-4 border-black"
            :vertical="xl"
          />
          <v-col class="flex flex-col">
            <u-text class="text-body-lead font-bold mb-4 grow">
              Qualified advisers
            </u-text>
            <u-text
              class="font-galano xxl:text-right leading-none"
              text="27k"
              font-size="112"
              color="type-primary"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import '@egjs/vue3-flicking/dist/flicking.css'
// import '@egjs/flicking-plugins/dist/pagination.css'
import Flicking from '@egjs/vue3-flicking'
import { Pagination } from '@egjs/flicking-plugins'

const { xxl, xl, lgAndDown, mdAndUp } = useDisplay()

const sliderOptions = computed(() => {
  return {
    align: 'prev',
    // moveType: 'freeScroll',
    // bound: true,
    circular: false,
    autoInit: true,
    panelsPerView: 1,
    // ...(lg.value
    //   ? {
    //       panelsPerView: 1.5,
    //     }
    //   : {}),
  }
})
const plugins = [
  new Pagination({
    type: 'bullet',
  }),
]
</script>

<style scoped lang="scss">
.flicking-pagination {
  @apply absolute bottom-0 left-0 right-0 flex gap-3 px-4 sm:px-8;
}

:deep(.flicking-pagination-bullet) {
  @apply w-4 h-4 rounded-circle cursor-pointer bg-black-16;
}

:deep(.flicking-pagination-bullet-active) {
  @apply bg-primary-teal;
}
</style>
