import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPageTitle, LazyUTable, LazyFaq, LazyUHighlight, LazyUSummaryCard, LazyCompoundCalculator, LazyPensionCalculator, LazyArticleLayout, LazyCategoryLayout, LazyDefaultLayout, LazyHomeLayout, LazyArticleAuthor, LazyCtaWidget, LazyCategoryAlternativeCard, LazyHomeSection, LazyHomeSectionContent, LazyUBreadcrumbs, LazyUBtn, LazyUBtnGroup, LazyUFeatureCard, LazyUInfoCard, LazyUCheckbox, LazyUExpansionPanel, LazyUIcon, LazyUIllustration, LazyULink, LazyULogo, LazyUSlider, LazyUText, LazyUTextField, LazyUContent } from '#components'
const lazyGlobalComponents = [
  ["PageTitle", LazyPageTitle],
["UTable", LazyUTable],
["Faq", LazyFaq],
["UHighlight", LazyUHighlight],
["USummaryCard", LazyUSummaryCard],
["CompoundCalculator", LazyCompoundCalculator],
["PensionCalculator", LazyPensionCalculator],
["ArticleLayout", LazyArticleLayout],
["CategoryLayout", LazyCategoryLayout],
["DefaultLayout", LazyDefaultLayout],
["HomeLayout", LazyHomeLayout],
["ArticleAuthor", LazyArticleAuthor],
["CtaWidget", LazyCtaWidget],
["CategoryAlternativeCard", LazyCategoryAlternativeCard],
["HomeSection", LazyHomeSection],
["HomeSectionContent", LazyHomeSectionContent],
["UBreadcrumbs", LazyUBreadcrumbs],
["UBtn", LazyUBtn],
["UBtnGroup", LazyUBtnGroup],
["UFeatureCard", LazyUFeatureCard],
["UInfoCard", LazyUInfoCard],
["UCheckbox", LazyUCheckbox],
["UExpansionPanel", LazyUExpansionPanel],
["UIcon", LazyUIcon],
["UIllustration", LazyUIllustration],
["ULink", LazyULink],
["ULogo", LazyULogo],
["USlider", LazyUSlider],
["UText", LazyUText],
["UTextField", LazyUTextField],
["UContent", LazyUContent],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
